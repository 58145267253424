/**
 * Component classes registered by plugins.
 *
 */

@import "print.css";

@import "tables.css";

@import "prism.css";

@tailwind components;

input {
  padding: 5px !important;
}

.sign-in-page label {
  margin-bottom: 5px;
}

.create-partner-div label,
.create-contact-div label {
  margin-left: 6px;
}

.pac-container {
  z-index: 99999;
}

.contact-list-table-heading {
  font-size: 14px !important;
}

.create-contact-address-search .pac-target-input {
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 360px;
  padding: 10px !important;
}

.contact-list-page {
  margin-top: 20px;
}

.wi-70 {
  width: 70%;
}

.wi-60 {
  width: 60%;
}

.wi-40 {
  width: 40%;
}

.wi-30 {
  width: 30%;
}

.google-map-div {
  padding: 0px 50px;
}

.map-container {
  height: 100%;
  width: 100%;
}

.hide-google-address-search {
  /* position: absolute; */
  /* bottom: 0px; */
}

.contact-details-header-data {
  text-align: center;
}

.show-google-address-search {}

.create-job-with-contact {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-bottom: 10px;
}

.primary-color {
  color: #0284c7;
}

.grey-color {
  color: #6b7280;
}

.green-button,
.green-button:hover {
  background-color: #04966a !important;
  color: white !important;
  cursor: pointer;
}

.login-sub-heading {
  font-size: 3.4rem;
  margin-top: 10px;
}

.signup-header {
  font-size: 4.2rem;
}

.user-profile-content {
  margin-left: 15px;
  margin-top: 16px;
  width: 100%;
}

.user-border-bottom {
  border-bottom: 1px solid #374151 !important;
}

.form-input-content {
  width: 100%;
}

.user-profile-dots {
  float: right;
  padding: 0px;
  top: -30px;
}

.vertical-navigation {
  margin-top: 25px;
}

.vertical-navigation .active {
  background-color: #0369a1 !important;
}

.top-toolbar {
  background-color: #1f2937 !important;
}

.white-color {
  color: white !important;
}

.white-color svg {
  color: #ffffff !important;
}

.text-center {
  text-align: center !important;
}

.toolbar-color {
  color: #111827 !important;
  font-weight: bold;
  font-size: 28px;
}

.jobs-list-tab {
  flex-direction: row;
}

.bg-white {
  background-color: #ffffff;
}

.bottom-border {
  border-bottom: 1px solid #eeeeee;
}

.job-details-card {
  background-color: #075985;
  color: #f3f4f6;
}

.contact-details-edit-icon {
  margin-top: 20px;
  height: 14px;
  width: 14px;
  min-width: 14px !important;
  min-height: 14px !important;
  cursor: pointer;
}

.contact-detail-field {
  border-bottom: 1px solid #ccc;
  padding-bottom: 6px;
  margin-bottom: 5px;
}

.create-job-google-address-search {
  border-radius: 4px;
  position: relative;
  background-color: #fcfcfb;
  border: 1px solid #ced4da;
  font-size: 16px;
}

.job-details-top {
  padding: 5px;
}

.flex-35 {
  flex: 1 1 35% !important;
}

.flex-65 {
  flex: 1 1 65% !important;
}

.flex-70 {
  flex: 1 1 70% !important;
}

.flex-60 {
  flex: 1 1 60% !important;
}

.flex-40 {
  flex: 1 1 40% !important;
}

.padding-right-zero {
  padding-right: 0px;
}

.job-details-primary-button,
.job-details-primary-button:hover {
  background-color: #bae6fd;
  color: #0c4a6e;
  border-radius: 6px;
}

.job-details-secondary-button {
  background-color: #075985;
  color: #e5e7eb;
  border-radius: 6px;
  border: 1px solid #bae6fd;
}

.font-bold {
  font-weight: bold;
}

.job-detail-bottom-content {
  /* padding-left: 24px; */
  padding-top: 20px;
  font-size: 14px;
  color: #1f2937;
}

.mobile-job-detail-bottom-content {
  width: 50%;
  float: left;
}

.jobs-list-header {
  padding: 15px 10px;
  border-radius: 6px;
}

.jobs-list-header button {
  background-color: #059669;
}

.jobs-list-header button:hover {
  background-color: #059669;
}

.jobs-list-content {
  padding-left: 15px;
  padding-right: 15px;
}

.job-list-tables,
.partners-list-tables {
  padding-top: 10px;
}

.partners-list-table-heading span {
  font-size: 12px !important;
}

.dialog-title {
  text-align: center;
  width: 84%;
}

.dialog-button-continue {
  background-color: #0284c7;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}

.dialog-button-continue:hover {
  background-color: #0284c7;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}

.invoice-paid-button:hover {
  background-color: #0284c7;
  color: #f9fafb;
}

.dialog-button-back {
  background-color: #e0f2fe;
  color: #0284c7;
  border-radius: 6px;
}

.dialog-button-back:hover {
  background-color: #e0f2fe;
  color: #0284c7;
  border-radius: 6px;
}

.dialog-button-invite {
  background-color: #059669;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
  padding-left: 20px;
}

.dialog-button-invite:hover {
  background-color: #059669;
  color: #f9fafb;
  border-radius: 6px;
  float: right;
}

.form-input-label:hover,
.form-input-label.Mui-focused {
  color: #374151 !important;
}

.create-job-type-label {
  line-height: 30px !important;
}

.create-job-step1,
.create-job-step2 {
  margin-bottom: 30px;
}

.dialog-buttons {
  padding: 20px 30px 20px 30px;
  display: inline;
}

.view-work-type-dialog .MuiDialog-paper {
  max-width: 1130px;
  min-height: 580px;
}

.sci-dialog .MuiDialog-paper {
  width: 100%;
}

.sci-dialog .MuiDialogContent-root {
  padding: 20px 50px;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  align-items: center;
  display: flex;
  justify-content: center;
}

.sci-dialog .MuiDialogContent-root .MuiFormControl-root {
  width: 85%;
}

.user-profile-dialog .MuiDialog-container .MuiPaper-root {
  background-color: #ffffff !important;
  color: rgb(17, 24, 39) !important;
}

.user-profile-icon-button {
  color: rgba(0, 0, 0, 0.54);
}

.user-profile-icon-button:hover {
  background-color: rgba(0, 0, 0, 0.04);
}

.user-profile-page-icon {
  float: left;
  margin-right: 2px;
  margin-top: -2px;
}

.no-profile-image {
  width: 48px;
  height: 48px;
}

.display-profile-image {
  float: left;
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.profile-image-upload-btn {
  border: 1px solid #ccc;
  margin-left: 22px;
  border-radius: 10px;
  margin-top: 2px;
}

.form-input-label {
  font-size: 18px;
  line-height: 18px;
  font-weight: 500;
  color: #374151;
  position: relative;
  margin-top: 5px;
}

.job-step-dots {
  /* width: 12px; */
  margin: 5px 5px;
  background-color: #e5e7eb;
  padding: 2px;
}

.dialog-steps {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.margin-top-20 {
  margin-top: 20px;
}

.step-text {
  font-size: 12px;
  margin-right: 10px;
  font-weight: bold;
}

.mobile-create-job-dialog-content {
  padding: 0px !important;
}

.web-create-job-dialog-content {
  padding: 20px 50px !important;
}

.web-create-job-dialog-content .MuiFormControl-root {
  /* margin-top: 20px; */
}

.mobile-create-job-dialog-content label+.MuiInputBase-root {
  width: 100% !important;
}

.current-step {
  background-color: #0284c7;
  box-shadow: 0px 0px 8px 3px #bae6fd;
}

.mobile-create-job-dialog-content .step-text {
  font-size: 10px !important;
  margin-right: 13px !important;
}

.web-create-job-dialog-content .step-text {
  font-size: 13px !important;
  margin-right: 13px !important;
}

.web-create-job-dialog-content .job-step-dots {
  padding: 3px !important;
}

.create-job-radio.Mui-checked svg:nth-of-type(1) {
  color: #10b981 !important;
}

.create-job-radio.Mui-checked svg:nth-of-type(2) {
  color: #10b981 !important;
}

.create-job-radio-label-selected {
  background-color: #a7f3d0;
  border-radius: 6px;
}

.create-job-radio-label,
.create-job-radio-label:hover {
  color: #374151;
}

.web-create-job-dialog-content .create-job-step3 {
  margin: auto -50px !important;
}

.form-field-error {
  color: red;
  margin: 5px 0px 0px;
  font-size: 14px;
}

.field-hide {
  display: none;
}

.jobs-list-table th {
  background-color: #f9fafb !important;
  color: #9ca3af;
  border-top: 1px solid #e5e7eb;
  border-bottom: 1px solid #e5e7eb;
}

.jobs-list-table th:nth-of-type(1) {
  border-radius: 6px 0px 0px 0px;
  border-left: 1px solid #e5e7eb;
  padding-left: 20px;
}

.jobs-list-table th:nth-last-child(1) {
  border-radius: 0px 6px 0px 0px;
  border-right: 1px solid #e5e7eb;
}

.mobile-job-customer-name {
  color: #111827;
  font-weight: 500;
}

.mobile-job-address {
  color: #374151;
}

.margin-top-20 {
  margin-top: 20px;
}

.jobs-list-table td {
  border-bottom: 1px solid #e5e7eb;
}

.mobile-add-document-dialog-content {
  padding: 10px !important;
}

.mobile-add-document-dialog-content>.MuiFormControl-root {
  height: 400px;
}

.job-list-table-row td:nth-of-type(1),
.partner-list-table-row td:nth-of-type(1) {
  border-left: 1px solid #e5e7eb;
  padding-left: 20px;
}

.job-list-table-row td:nth-last-child(1),
.partner-list-table-row td:nth-last-child(1) {
  border-right: 1px solid #e5e7eb;
}

.job-detail-heading {
  font-size: 16px;
  color: #1f2937;
  margin-left: 0px !important;
}

.job-details-tab,
.job-detail-details {
  color: #1f2937;
}

.job-detail-main {
  background-color: #f9fafb;
  min-height: 100%;
}

.job-detail-tabs .MuiTabs-scroller {
  margin-bottom: 15px !important;
  margin-left: 15px;
}

.job-detail-tabs .MuiTabs-flexContainer button {
  padding-top: 0px !important;
}

.job-detail-tabs .MuiTabs-flexContainer .Mui-selected {
  background-color: #e0f2fe;
  border-radius: 8px;
}

.job-detail-tabs .MuiTabs-flexContainer .Mui-selected .job-detail-tab-icon svg {
  color: #0ea5e9;
}

.job-detail-tabs .MuiTabs-flexContainer .Mui-selected .job-detail-mobile-tab-icon svg {
  color: #0ea5e9;
}

.job-detail-tabs-list {
  display: inline;
}

.add-quote-line-item-tabs .MuiTabs-scroller {
  margin-bottom: 15px !important;
  margin-left: 15px;
}

.add-quote-line-item-tabs .MuiTabs-flexContainer button {
  padding-top: 15px !important;
}

.add-quote-line-item-tabs .MuiTabs-flexContainer .Mui-selected {
  background-color: #e0f2fe;
  border-radius: 8px;
}

.add-quote-line-item-tabs .MuiTabs-flexContainer .Mui-selected .job-detail-tab-icon svg {
  color: #0ea5e9;
}

.add-quote-line-item-tabs .MuiTabs-flexContainer .Mui-selected .job-detail-mobile-tab-icon svg {
  color: #0ea5e9;
}

.add-quote-line-item-tabs-list {
  display: inline;
}



.job-detail-tab-icon {
  float: left;
  padding-top: 14px;
}

.job-detail-mobile-tab-icon svg {
  /* width: 100%; */
  margin-left: auto;
  margin-right: auto;
}

.job-detail-mobile-tab-icon {
  margin-top: 5px;
}

.job-detail-tab-heading {
  float: right;
  padding-top: 14px;
  padding-left: 5px;
}

.jobs-list-table-heading {
  font-size: 12px;
}

.web-jobs-list-table-heading {
  font-size: 14px;
}

.mobile-partners-list-table-heading {
  font-size: 12px;
}

.web-partners-list-table-heading {
  font-size: 14px;
}

.mobile-job-assignment-content {
  position: absolute;
  top: 50px;
  background-color: #f9fafb;
}

.mobile-job-assignment-content .job-details-card {
  margin: 15px;
}

.mobile-detail-content {
  /* position: absolute;
    top: 360px; */
  background-color: #f9fafb;
}

.quotes-accordion {
  background-color: #f9fafb;
}

.request-quote-dialog .MuiDialogContent-root {
  width: 100%;
  padding: 0px;
  border-top: 0px;
}

.request-quote-dialog-tabs-div,
.mobile-request-quote-dialog-tabs-div {
  width: 100%;
}

.user-profile-dialog-tabs-div {
  width: 100%;
  margin: auto;
}

.request-quote-dialog-tabs-div .MuiTabs-flexContainer,
.user-profile-dialog-tabs-div .MuiTabs-flexContainer {
  justify-content: center;
}

.web-request-partners-list {
  padding: 0px 85px 20px 85px;
  /* height: 50vh; */
  max-height: 50vh;
  overflow: scroll;
}

.web-request-partners-list img {
  height: 36px;
  width: 36px;
  border-radius: 30px;
}

.mobile-request-partners-list img {
  height: 32px;
  width: 32px;
  border-radius: 30px;
}

.clear {
  clear: both;
}

.mobile-request-partners-list .request-partner {
  padding: 20px;
}

.web-request-partners-list .request-partner {
  padding: 20px;
}

.web-request-partners-list .requester-name {
  padding: 0px 10px;
  max-width: 60%;
}

.mobile-request-partners-list .requester-name {
  padding: 0px 10px;
  max-width: 53%;
}

.requester-name-label {
  color: #111827;
  font-size: 14px;
  font-weight: 500;
}

.requester-email-label {
  color: #6b7280;
  font-size: 14px;
}

.request-partner {
  border-top: 1px solid #e2e8f0;
}

.request-quote-dialog h2 {
  padding-bottom: 0px;
}

.requested-button,
.requested-button:hover {
  border: 1px solid #d1d5db;
  color: #374151;
  background-color: #ffffff;
  border-radius: 6px;
  font-weight: 500 !important;
}

.request-button,
.request-button:hover {
  background-color: #0284c7 !important;
}

.cursor-auto {
  cursor: auto;
}

.quote-list-quote-status {
  background-color: #bae6fd;
  padding: 5px 10px;
  border-radius: 10px;
  font-size: 14px;
  color: #1f2937;
  line-height: 16px;
  font-weight: 500;
}

.quote-request-card {
  margin-right: 15px !important;
  margin-bottom: 20px;
  border: 1px solid #e5e7eb;
}

.quote-request-card {
  margin-right: 15px;
  /* border: 1px solid #E5E7EB; */
}

.quote-list-second-line {
  margin-bottom: 15px;
  margin-top: 5px;
}

.quote-list-quote-id {
  margin-right: 20px;
  color: #4b5563;
}

.quote-list-hash {
  color: #9ca3af;
}

.quote-list-quote-date {
  color: #4b5563;
}

.quote-list-quote-date svg,
.quote-list-quote-amount svg {
  margin-right: 5px;
  margin-top: 1px;
}

.quote-list-decline-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  color: #374151;
}

.quote-list-approve-button,
.quote-list-approve-button:hover {
  background-color: #10b981;
  color: #ffffff;
}

.pending-quote-badge {
  background-color: #f3f4f6;
  color: #1f2937;
  border-radius: 10px;
  padding: 5px;
}

.received-quote-badge {
  background-color: #0ea5e9;
  color: #f9fafb;
  border-radius: 10px;
  padding: 5px;
}

.quote-list-account-name {
  color: #0c4a6e;
  font-size: 16px;
  font-weight: 500;
}

.web-detail-content {
  background-color: #f9fafb;
  padding-right: 0px;
  flex: 1 1 28% !important;
  min-height: 100vh;
}

.quotes-list-accordion-details {
  padding-left: 5px;
}

.quotes-list-tab .Mui-expanded {
  margin: 12px 0px;
  min-height: min-content;
}

.empty-partners-list {
  padding: 20px;
  text-align: center;
}

.web-request-partners-list .empty-partners-list {
  padding-bottom: 0px;
}

.display-none {
  display: none !important;
}

.max-width-200 {
  max-width: 200px;
}

.max-width-150 {
  max-width: 150px;
}

.max-width-180 {
  max-width: 180px;
}

.primary-button,
.primary-button:hover {
  background-color: #0369a1 !important;
  color: white !important;
}

.add-files-btn {
  float: right;
  margin-bottom: 20px;
}

.view-files-btn {
  float: left;
  margin-bottom: 20px;
}

.job-detail-attachment-link {
  background: none !important;
  border: none !important;
  text-decoration: underline !important;
}

.max-width-240 {
  max-width: 240px;
}

.create-partner-input input {
  padding: 5px;
}

.job-feed-sort-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
}

.conversation-profile-pic {
  height: 48px;
  width: 48px;
  border-radius: 100%;
}

.conversation-no-profile {
  height: 48px;
  width: 48px;
  border-radius: 100%;
}

.dashboard-profile-pic {
  height: 38px;
  width: 38px;
  border-radius: 100%;
}

.dashboard-no-profile {
  height: 38px;
  width: 38px;
  border-radius: 100%;
}

.conversation-reply-profile-pic {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.conversation-reply-no-profile {
  height: 24px;
  width: 24px;
  border-radius: 100%;
}

.conversation-reply-pic div {
  margin: 0px 5px;
}

.conversation-image-div {
  margin-right: 15px;
}

.each-conversation {
  border-top: 1px solid #e5e7eb;
  padding: 15px 0px;
}

.conversation-user-name {
  color: #111827;
  font-size: 14px;
  font-weight: 600;
}

.conversation-account-name {
  color: #6b7280;
  font-size: 12px;
}

.conversation-message {
  margin-top: 5px;
  color: #111827;
  font-size: 14px;
}

.conversation-created-time {
  color: #374151;
  font-size: 12px;
}

.conversation-created-time-right {
  float: right;
  position: absolute;
  right: 15px;
}

.conversation-reply-link {
  margin-top: 5px;
  font-size: 12px;
  color: #4b5563;
  margin-bottom: 5px;
}

.conversation-reply-link svg {
  float: left;
}

.conversation-reply-count {
  color: #0284c7;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
}

.feed-conversations {
  margin-top: 15px;
}

.quote-feed-conversations {
  margin-top: 15px;
  float: left;
  width: 49%;
  border-right: 1px solid #e5e7eb;
  padding-right: 15px;
}

.quote-shared-information {
  width: 48%;
  float: left;
  border-top: 1px solid #e5e7eb;
  margin: 15px;
}

.quote-shared-information-heading {
  text-align: center;
  font-size: 20px;
  margin: 18px;
  font-weight: bold;
}

.create-conversation .MuiFormControl-root {
  background-color: #ffffff;
}

.assign-partner-label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 8px;
}

.assign-partner-name .MuiSelect-select {
  padding: 0px;
}

.assign-job-dialog-div {
  margin: 20px;
}

#assign-partner-name {
  padding-top: 7px;
  padding-left: 5px;
}

.assign-partner-name {
  width: 96% !important;
}

.jobs-table-pagination .MuiTablePagination-select {
  padding-top: 15px;
}

.job-details-back {
  position: absolute;
  color: #9ca3af;
}

.job-details-heading {
  margin: auto;
  align-items: center !important;
}

.job-detail-fuse-carded .fuse-carded-container {
  border-radius: 0px;
  box-shadow: none;
}

.job-detail-fuse-carded .fuse-carded-container .FusePageCarded-contentWrapper {
  overflow: hidden !important;
}

.feed-list-heading {
  color: #1f2937;
  float: left;
  margin-top: 5px;
}

.mobile-job-feed-sort-button {
  background-color: #ffffff;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  float: right;
}

.mentions-input-text__control input {
  top: -40px !important;
  left: 20px !important;
  z-index: 9 !important;
}

.create-conversation-input {
  z-index: 9999;
}

.mentions-input-text {
  width: 95%;
}

.mentions-input-text__highlighter {
  background-color: #f9fafb;
}

.jobs-list-page .fuse-carded-container {
  border-radius: 0px !important;
}

.mobile-jobs-list-fitlers {
  float: right;
}

.web-jobs-list-fitlers {
  float: right;
  width: 20%;
}

.mobile-jobs-list-fitlers .jobs-list-filters {
  max-width: 130px;
  margin: 0px;
}

.web-jobs-list-fitlers .jobs-list-filters {
  margin: 0px;
  float: right;
}

.web-catalogs-list-fitlers {}

.mobile-jobs-list-header {
  padding: 15px 0px !important;
}

.each-attachment {
  padding: 10px 5px;
  border-bottom: 1px solid #e5e7eb;
  border-left: 1px solid #e5e7eb;
  border-right: 1px solid #e5e7eb;
}

.attachment-icons {
  margin: 0px 5px;
}

.web-request-proposal-dialog-content {
  /* border-bottom: none !important; */
}

.share-product-input {
  float: left;
  width: 235px !important;
}

.web-share-product-link-dialog-content {
  display: block !important;
}

.share-email-label {
  margin-right: 18px !important;
}

.share-product-button {
  background-color: #0284c7 !important;
  color: #f9fafb !important;
  border-radius: 6px;
  float: left;
  margin-left: 10px;
  margin-top: 8px;
}

.each-attachment:first-child {
  border-top: 1px solid #e5e7eb;
  border-radius: 4px 4px 0px 0px;
}

.each-attachment:last-child {
  border-radius: 0px 0px 4px 4px;
}

.attachment-download-icon {
  color: #059669;
}

.attachment-delete-icon {
  color: #f46a36;
}

.attachments-list-div {
  margin-top: 10px;
}

.attachments-header {
  font-size: 16px;
  font-weight: 500;
}

.attachment-left-div {
  max-width: 80%;
}

.user-profile-content .account-name {
  max-width: 90%;
}

.account-name .username {
  max-width: 90%;
}

.jobs-list-filters .MuiSelect-select,
.catalogs-list-filters .MuiSelect-select {
  padding-bottom: 10px;
}

.catalogs-filters-span {
  width: 200px;
  float: left;
  text-align: right;
  line-height: 50px;
}

.catalogs-filters-span-mobile {
  width: 200px;
  float: left;
  text-align: left;
  line-height: 20px;
}

.mobile-catalogs-list-fitlers {
  margin-bottom: 20px;
}

.each-uploaded-file {
  float: left;
  padding: 5px 10px;
  clear: both;
}

.remove-upload-file {
  float: right;
  color: white !important;
  margin: 50px;
}

.dashboard-type-filter {
  margin-left: 50px;
}

.mobile-dashboard-recent-activity {
  margin-top: 10px;
}

.dashboard-type-filter-label {
  margin-top: 12px;
  margin-right: 10px;
}

#dashboard-type-filter-dd {
  width: 150px;
}

.each-slide-effect>div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 350px;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}

.react-slideshow-wrapper.slide {
  width: 480px !important;
}

.customer-engagement-portal-header {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 20px;
  min-height: 100px;
}

.customer-engagement-portal-header-mobile {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 20px;
  min-height: 175px;
}

.customer-engagement-portal-header .active,
.customer-engagement-portal-header-mobile .active {
  color: #0989cb;
}

.customer-engagement-portal-number {
  float: left;
  font-size: 24px;
  border: 2px solid black;
  border-radius: 50%;
  padding: 10px;
  font-weight: bold;
  /* color: #0989cb; */
}

.customer-engagement-portal .active .customer-engagement-portal-number {
  color: #0989cb !important;
  border: 2px solid #0989cb !important;
}

.customer-engagement-portal-heading-text {
  margin: auto;
  vertical-align: middle;
  font-size: 18px;
  margin-top: 12px;
  margin-left: 75px;
  font-weight: bold;
  cursor: pointer;
}

.customer-engagement-portal-select,
.customer-engagement-portal-select:hover {
  background-color: #075985;
  border: 2px solid #0369a1;
  border-radius: 6px;
  padding: 20px;
  color: white;
  margin-top: 35px;
  margin-left: 40px;
}

.customer-engagement-portal-select-mobile,
.customer-engagement-portal-select-mobile:hover {
  background-color: #075985;
  border: 2px solid #0369a1;
  border-radius: 6px;
  padding: 20px;
  color: white;
  margin-top: 35px;
  /* margin-left: 40px; */
}

.customer-engagement-portal-send.Mui-disabled {
  opacity: 0.7;
  color: white !important;
}

.share-customer-portal-link-label {
  float: left;
  margin: auto;
  position: relative;
  margin-top: 17px;
  margin-right: 25px;
  margin-left: 25px;
  color: #374151;
  font-size: 14px;
  font-weight: 500;
}

.customer-engagement-portal-send,
.customer-engagement-portal-send:hover {
  background-color: #075985;
  border: 2px solid #0369a1;
  border-radius: 6px;
  padding: 20px;
  color: white;
  margin-top: 35px;
  margin-left: 40px;
}

.job-details-company-logo {
  float: right;
  height: 95px;
  /* height: 120px; */
  /* min-width: 210px; */
}

.mobile-job-details-company-logo {
  float: right;
  /* height: 60px; */
  width: 31%;
}

.job-details-company-small-logo {
  height: 70px !important;
}

.create-new-question-content {
  padding: 25px;
}

.create-question-label {
  margin-left: 0px !important;
  max-width: 100%;
  overflow: visible !important;
}

.create-question-input {
  margin-left: 0px !important;
}

.create-new-question-heading {
  font-size: 16px;
}

.create-new-question-text-view {
  margin-top: 10px;
}

.work-type-each-question {
  border: 1px solid #CCC;
  padding: 15px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: move;
}

.view-work-type-question-order-title {
  font-weight: bold;
}

.width-95 {
  width: 95%;
}

.width-90 {
  width: 90%;
}

.draggable-icon {
  background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="%232196F3" d="M11 18c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2zm-2-8c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm6 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"></path></svg>');
  /* background-image: url('data:image/svg+xml;utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 24 24"><path fill="%232196F3" d="M 5,3L 19,3L 20.7359,6L 20.7304,6C 20.9018,6.29149 21,6.63428 21,7L 21,19C 21,20.1046 20.1046,21 19,21L 5,21C 3.89543,21 3,20.1046 3,19L 3,7C 3,6.638 3.09618,6.29846 3.26437,6L 3.26135,6L 5,3 Z M 5.57294,4L 5,5L 5,5L 19,5L 18.4303,4L 5.57294,4 Z M 7,12L 12,17L 17,12L 14,12L 14,10L 10,10L 10,12L 7,12 Z "></path></svg>'); */
}

.job-details-company-logo-div {
  float: right;
  position: absolute;
  /* right: 30px; */
  right: 0px;
  padding-right: 5.6rem;
  z-index: 9999;
}

.mobile-job-details-company-logo-div {
  float: right;
  position: absolute;
  /* right: 30px; */
  right: 0px;
  padding-right: 10px;
  z-index: 9999;
  margin-left: 85px;
}

.sci-dialog .mobile-create-job-dialog-div>.MuiFormControl-root {
  height: 400px !important;
}

.job-details-header {
  height: 90px;
}

.job-details-header .job-details-back {
  margin-top: 20px;
}

.create-job-existing-contact-div {
  min-height: 75px !important;
  /* margin-top: 10px; */
}

.request-quote-dialog-tabs-div .MuiTabs-scroller .MuiTabs-flexContainer,
.mobile-request-quote-dialog-tabs-div .MuiTabs-scroller .MuiTabs-flexContainer {
  padding-left: 30px;
}

.mobile-request-quote-dialog-tabs-div {
  height: 400px;
}

.mobile-height-400 {
  height: 400px;
}

.product-image-preview {
  height: 100px;
  width: 100px;
}

.company-logo-preview {
  height: 120px;
}

.quoteAmountLabel {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 5px;
}

.quoteAmountField {
  background-color: white;
}

.submitQuote,
.submitQuote:hover {
  width: 100%;
  border-radius: 6px;
  margin: 10px 0px;
  background-color: #059669;
}

.declineQuote,
.declineQuote:hover {
  width: 100%;
  border-radius: 6px;
  background-color: #075985;
  border: 2px solid #0369a1;
}

.quoteAmountDisplay {
  justify-content: center;
}

.quoteAmountDollor {
  color: white;
  margin-right: 5px;
}

.quoteEditButton,
.quoteEditButton:hover {
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: transparent;
}

.page-heading {
  font-weight: 700;
  font-size: 32px;
  margin-right: 30px;
}

.add-attachment-label {
  color: #6b7280;
  font-size: 16px;
}

.add-attachment-icon {
  color: #9ca3af;
  margin-right: 10px;
  font-size: 24px;
}

.update-job-details-button-section {
  position: fixed !important;
  left: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  background-color: rgba(231, 231, 231, 0.7) !important;
  color: white !important;
  text-align: center !important;
  padding: 15px !important;
}

.show-update-button {
  display: block;
}

.hide-update-button {
  display: none !important;
}

.update-job-detail-button-cancel {
  background-color: white;
  color: #1e293b;
}

.update-job-detail-button-update {
  margin-left: 10px;
}

.job-detail-edit-text {
  border: 1px solid #1f2937;
  border-radius: 5px;
  margin-top: -10px;
  /* margin-left: 10px; */
}

.job-detail-edit-text-main {
  margin-left: 10px;
}

.google-map-div {
  margin-left: 25px;
}

.contact-details-left {
  width: 95%;
}

.contact-details-field {
  width: 105%;
}

.margin-auto {
  margin: auto;
}

.contact-search-label {
  display: inline-block;
  text-decoration: underline;
}

.contact-search-address {
  display: inline-block;
  /* margin-top: -10px; */
  font-size: 12px;
  color: rgb(107, 114, 128);
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.contact-details-field .pac-target-input {
  border: 1px solid #ccc;
  width: 100%;
  border-radius: 5px;
}

.contact-search-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  text-decoration: none;
  width: 100%;
  box-sizing: border-box;
  text-align: left;
  margin: 0px;
  padding: 0px;
}

.contact-search-item-data {
  flex: 1 1 auto;
  min-width: 0;
  margin: 0px;
  padding: 0px;
}

.create-job-step1 .MuiAutocomplete-root,
.create-job-step1 .MuiAutocomplete-root .MuiFormControl-root.MuiFormControl-fullWidth {
  width: 100% !important;
}

.contact-detail-edit-field {
  margin-top: 5px;
  margin-left: 0px;
}

.edit-job-status,
.edit-scheduled-status {
  width: 100%;
}

.edit-job-status .MuiSelect-select,
.edit-scheduled-status .MuiSelect-select {
  padding: 8px 0px 0px 10px !important;
}

.new-job-button {
  float: left;
}

.job-search-filter-box {
  margin-left: 40px;
  margin-top: 0px;
  width: 33%;
}

.jobs-page-heading {
  width: 7%;
}

.job-page-new-job-button {
  width: 13%;
  max-width: 140px;
}

.jobs-page-search-filter-box {
  width: 75%;
}

.jobs-list-page-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56%;
}

.roofing-jobs-list-page-search-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 65%;
}

.roofing-job-search-filter-box {
  margin-left: 40px;
  margin-top: 0px;
  width: 70%;
}

.mobile-job-search-filter-box {
  /* margin-left: 7px; */
  margin-top: 0px;
  width: 100%;
  /* margin: 10px 40px !important; */
  /* padding-right: 20px;
    padding-left: 20px; */
  margin-top: 20px;
}

.job-search-filter-box .MuiTextField-root,
.mobile-job-search-filter-box .MuiTextField-root,
.jobs-page-search-filter-box .MuiTextField-root,
.roofing-job-search-filter-box .MuiTextField-root {
  margin-top: 0px;
}

.jobs-search-divider {
  margin-left: 0px;
}

.create-post-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.create-post-input-div {
  float: left;
  width: 80%;
  margin-left: 20px;
  margin-top: 4px;
  background-color: white;
  /* border-radius: 46px; */
  border-radius: 50px;
}

.create-conversation-post {
  /* margin-top: 20px; */
}

.job-datepicker-image {
  float: right;
  height: 24px;
  width: 24px;
  margin-right: 35px;
}

.show-update-job-date {
  display: block !important;
  position: fixed !important;
  left: 280px !important;
  bottom: 0 !important;
  width: 85% !important;
  background-color: rgba(231, 231, 231, 0.7) !important;
  color: white !important;
  text-align: center !important;
  padding: 15px !important;
  right: 0px;
  margin-right: 0px;
  z-index: 999;
}

.jobs-datepicker-cancel {
  color: #0e74d5 !important;
  margin-right: 10px !important;
  background-color: white !important;
  cursor: pointer;
}

.jobs-datepicker-update {
  background-color: #1976d2;
  cursor: pointer;
}

.hide-update-job-date {
  display: none !important;
}

.create-conversation-input-div .MuiInputBase-root {
  border-radius: 50px;
}

.create-conversation-input-div input {
  padding-left: 15px !important;
}

.mobile-job-results {
  width: 92% !important;
}

.mobile-roofing-jobs-list-header {
  padding: 0px;
  padding-top: 20px;
}

.roofing-jobs-list-filters {
  width: 100% !important;
  margin: 0px !important;
}

.show-job-search-results {
  width: 40%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}

.mobile-show-job-search-results {
  width: 92%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}

.roofing-show-job-search-results {
  width: 44%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: block;
  z-index: 9999;
  position: absolute !important;
  max-height: 480px;
  overflow-y: scroll;
  background-color: white;
}

.hide-job-search-results {
  width: 32%;
  border: 1px solid #ccc;
  cursor: pointer;
  display: none;
  z-index: 9999;
  position: absolute !important;
  background-color: white;
}

.create-conversation-input-div .Mui-focused fieldset {
  border: 1px solid silver !important;
}

.conversation-content-div {
  width: 70%;
}

.conversation-replies-list {
  margin-top: 10px;
}

.conversation-replies-list .conversation-content-div {
  width: 60% !important;
}

.mention-highlight {
  color: #4a90e2;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.account-list-link {
  text-decoration: underline !important;
  color: #000 !important;
}

.jobs-search-result-item span {
  text-decoration: underline;
}

.jobs-search-result-item:hover {
  background-color: #ccc;
}

.quote-list-table-body {
  background-color: white;
}

.jobs-list-page-content .MuiButtonBase-root.MuiTabScrollButton-root.MuiTabScrollButton-horizontal.Mui-disabled.MuiTabs-scrollButtons.MuiTabs-scrollButtonsHideMobile {
  display: none;
}

.jobs-list-filters .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input,
.web-jobs-list-fitlers .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input,
.calendar-partner-filters .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input,
.catalogs-list-filters .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
  padding: 10px;
  padding-top: 12px;
  padding-left: 20px;
  padding-bottom: 5px;
}

.calendar-partner-filters .MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input {
  padding-right: 26px;
}

.create-job-step3-content {
  /* margin-top: 20px; */
}

.partner-single-color {
  padding: 2px;
  margin-right: 2px;
  height: 10px;
  position: relative;
  display: inline-block;
}

.roof-work-type-dd,
.job-type-dd,
.roof-job-type-dd {
  margin-top: 0px !important;
  width: 100%;
}

.mobile-sign-in-page .-top-125 {
  top: -125px;
}

.mobile-sign-in-page-heading {
  font-size: 38px;
  margin-top: 20px;
}

.mobile-sign-in-page .login-sub-heading {
  font-size: 30px;
}

.mobile-sign-in-page {
  max-height: 275px;
}

.mobile-forgot-password {
  float: right;
  margin-top: 10px;
}

.forgot-password-input input {
  padding: 10px !important;
}

.web-profile-update {
  padding: 0px 85px 20px 85px;
}

.text-align-right {
  text-align: right;
}

.add-proposal-btn {
  margin-bottom: 10px;
}

.post-dialog-content {
  overflow: visible;
}

.create-post-dialog .MuiDialog-paper.MuiPaper-root {
  overflow: visible;
}

.create-post-account-name {
  font-size: 11px;
}

.jobs-list-table-row td {
  max-width: 250px;
}

.min-height-44 {
  min-height: 44px;
}

.create-document-label {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 20px;
  margin-left: 5px;
}

.create-document-dialog-div .MuiFormControl-root {
  margin: 5px;
  width: 97% !important;
}

.assign-partner-label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 8px;
}

.create-document-name .MuiSelect-select {
  padding: 0px;
}

.create-document-field,
#document-type-name,
#create-document-partner {
  padding-top: 7px;
  padding-left: 5px;
  padding-bottom: 0px;
}

.create-document-field {
  width: 96% !important;
}

.request-document-heading {
  margin-bottom: 30px;
  font-size: 16px;
}

.partner-document-button {
  margin-top: 8px;
}

.white-button {
  background-color: #fff;
  color: rgb(3, 105, 161);
  border: 1px solid #0284c7;
}

.red-button,
.red-button:hover {
  background-color: #f70300;
  color: #fff;
  border: 1px solid #f70300;
}

.MuiDialogContent-root.web-add-document-dialog-content .MuiFormControl-root,
.MuiDialogContent-root.web-create-job-dialog-content .MuiFormControl-root {
  margin: auto;
}

.attachments-list-grid {
  padding-top: 0px !important;
}

.dashboard-single-update {
  border-bottom: 1px solid #ccc;
  padding: 15px;
}

.job-updates-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.single-update:last-child {
  border-bottom: none;
}

.update-sub-text {
  color: #6b7280;
  font-size: 13px;
  margin-top: 2px;
}

.update-text {
  font-size: 14px;
}

.dashboard-updates-div {
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 25px;
  border-radius: 10px;
}

.dashboard-updates-icon {
  color: #3b8f73;
}

.dashboard-updates-icon-bg {
  background-color: #a7f3d0;
}

.dashboard-updates-heading {
  font-size: 16px;
  font-weight: 500;
  margin-top: 10px;
  margin-left: 20px;
}

.dashboard-updates-header {
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
  padding-left: 15px;
}

.dashboard-content-div {
  margin-left: 20px;
  max-width: 80%;
}

.invoice-action-buttons {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
  text-align: center;
}

.invoice-add-button {
  width: 300px;
  color: #ccc;
  text-align: center;
  padding: 50px;
  cursor: pointer;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

/* Upload Attachment */
#form-file-upload {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
}

.company-logo-form {
  height: 16rem;
  width: 28rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-top: 20px;
}

#input-file-upload {
  display: none;
}

#label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
}

#label-file-upload.drag-active {
  background-color: #ffffff;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

#drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.add-attachment-dialog {}

.quote_attachments_view {
  margin: 10px;
}

.quote_attachments_view a {
  color: white !important;
}

.vertical-bar-line {
  border-right: 1px solid #ccc;
  margin: 10px 0px;
}

.border-right {
  border-right: 1px solid #ccc;
}

.request-document-tabs {
  margin-bottom: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.height-50 {
  height: 50px;
}

.view-document-link {
  text-decoration: underline !important;
  background: none !important;
}

.attachment-download-icon-link {
  float: left;
  background: none !important;
  text-decoration: none;
  border: none;
}

.float-right {
  float: right;
}

.attachments-link {
  cursor: pointer;
}

#quote-form-file-upload {
  /* height: 16rem;
  width: 28rem; */
  max-width: 100%;
  text-align: center;
  position: relative;
  margin: auto;
  height: 70px;
  margin-top: 20px;
  background: transparent;
}

#quote-input-file-upload {
  display: none;
}

#quote-label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  background: transparent;
}

#quote-label-file-upload.drag-active {
  background-color: #ffffff;
}

.job-page-quotes-list {
  max-height: 400px;
  margin-top: 15px;
}

.job-details-edit-icon {
  height: 14px;
  width: 14px;
  min-width: 14px !important;
  min-height: 14px !important;
  cursor: pointer;
}

.job-details-edit-div {
  justify-content: space-between;
}

.dialog-button-continue.Mui-disabled {
  background-color: #ccc;
}

.uploaded-files-heading {
  font-weight: bold;
  margin-bottom: 10px;
}

.empty-uploaded-files {
  text-align: center;
  margin-top: 20px;
}

.empty-uploaded-files-message {
  margin-top: 20px;
}

.add-attachment-list {
  margin: auto;
  padding: 20px 5px;
  text-align: left;
}

.company-logo-image-preview {
  padding-top: 0px;
  margin-top: -15px;
  margin-left: 30px;
}

.company-logo-current-image-preview {
  padding-top: 0px;
  margin-top: -15px;
  margin-left: 30px;
}

.left-horizontal-dotted-line {
  border-top: 2px dashed #CCC;
  margin-left: 10px;
  margin-bottom: 15px;
}

.right-horizontal-dotted-line {
  border-top: 2px dashed #CCC;
  margin-right: 15px;
  margin-bottom: 15px;
}

.quote-line-item-each-attachment-view {
  margin-top: 10px;
  margin-left: 10px;
}

.quote-line-item-each-attachment-view a {
  text-decoration: underline !important;
  background-color: white !important;
  margin-top: 5px;
  border-bottom: none !important;
}

.web-add-attachment-dialog-content {
  display: block !important;
}

.mobile-add-attachment-dialog-content {
  display: block !important;
}

.mobile-add-chargeback-dialog-content,
.web-add-chargeback-dialog-content {
  display: block !important;
}

.mobile-job-detail-tab {
  padding-bottom: 20px;
}

.fuse-left-nav-icon {
  height: 24px;
  width: 24px;
  margin-right: 16px;
}

.create-service-territory-dd {
  height: 40px;
}

.create-service-territory-rule-label {
  padding-bottom: 3px;
}

.contact-view-site-details {
  margin-top: 20px;
}

.request-quote-service-territory-name {
  float: left;
  text-align: center;
  width: 85%;
  font-size: 14px;
  font-weight: bold;
  /* margin-left: -1px; */
  padding-left: 35px;
  margin-top: 5px;
}

.recaptcha-div {
  margin-bottom: 20px;
}

.trial-form-checkbox {
  position: absolute;
  float: left;
  left: 0px;
}

.trial-form-checkbox-label {
  margin-left: 45px;
}

.terms-and-conditions-div {
  margin: 20px;
}

.terms-and-condtions-link {
  text-decoration: underline !important;
  background: none !important;
  border: none !important;
}

.trial-verify-link {
  /* position: absolute; */
  /* right: 0px; */
  margin-left: 340px;
  /* margin-top: -55px; */
  cursor: pointer;
  text-decoration: underline;
  position: relative;
  float: right;
  margin-top: -15px;
  top: -44px;
  width: 80px;
}

.search-management-sub-heading {
  padding: 10px;
}

.find-partner-filters-div {
  float: left;
  width: 50%;
}

.find-partner-search-button-div {
  float: left;
  margin: auto;
  padding: 30px;
}

.find-partner-each-filter {
  width: 100%;
}

.find-partner-filter-label {
  float: left;
  width: 25%;
  padding: 15px;
  text-align: right;
}

.find-partner-filter-search-btn,
.find-partner-filter-search-btn:hover {
  background-color: #059669;
  color: #f9fafb;
  border-radius: 6px;
  /* float: right; */
  /* padding-left: 20px; */
  width: 160px;
  margin: auto;
}

.find-partner-fitlers-main {
  border-bottom: 1px solid #000;
  padding-bottom: 30px;
}

.find-partner-table {
  margin-top: 20px;
}

.trial-verify-button {
  margin-bottom: 15px;
}

.user-list-div {
  margin-top: 50px;
}

.login-as-user-btn,
.login-as-user-btn:hover {
  background-color: #0284c7;
}

.salesforce_link {
  text-decoration: underline !important;
  background: none !important;
  color: #0284c7 !important;
}

.calendar-months {
  margin-left: auto;
}

.calendar-partner-filters {
  margin-left: 20px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border: 1px solid currentColor;
  color: inherit;
  border-color: currentColor;
  text-transform: none;
  border-radius: 4px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  min-width: 140px;
  margin-top: 3px;
}

.customer-portal-view-web {
  height: 100vh;
}

.customer-portal-view-left-panel {
  width: 30%;
  float: left;
  height: 100vh;
  background-color: #f3f4f6;
  padding: 40px 70px;
}

.customer-portal-view-right-panel {
  width: 70%;
  float: left;
  /* height: 100vh; */
  background-color: #f9fafb;
}

.customer-portal-view-left-panel-mobile {
  background-color: #f3f4f6;
  padding: 30px;
  width: 100%;
  overflow: scroll;
  padding-bottom: 0px;
  margin-bottom: 30px;
}

.customer-portal-view-right-panel-mobile {
  background-color: #f9fafb;
  height: calc(100vh - 150px);
}

.customer-engagement-portal-header-left {
  float: left;
  margin-right: 250px;
}

.customer-engagement-portal-header-left-mobile {
  float: left;
  /* margin-right: 250px; */
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.customer-engagement-portal-header-right {
  float: left;
}

.customer-portal-view-left-label {
  color: #a9adb6;
}

.customer-portal-header-content-mobile {
  width: max-content;
  overflow: scroll;
}

.max-width-200 {
  max-width: 200px;
}

.customer-portal-view-right-header-mobile {
  border: 1px solid #ccc;
  padding: 20px 9px;
  border-radius: 20px;
  min-height: 80px;
  margin: 10px;
}

.customer-portal-view-right-header-mobile>.customer-portal-view-right-heading-title-mobile {
  margin-right: 10px;
}

.customer-portal-view-right-header {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 20px;
  min-height: 100px;
}

.customer-portal-view-right-mobile {
  padding-top: 10px;
}

.customer-portal-view-right-heading-title.active .customer-portal-view-right-number {
  border: 2px solid #0989cb !important;
  color: #0989cb !important;
}

.customer-portal-view-right-number {
  float: left;
  font-size: 24px;
  /* border: 2px solid #0989cb; */
  border: 2px solid #000;
  border-radius: 50%;
  padding: 11px;
  font-weight: bold;
  /* color: #0989cb; */
  width: 55px;
}

.customer-portal-view-right-heading-title-mobile.active .customer-portal-view-right-number-mobile {
  border: 2px solid #0989cb !important;
  color: #0989cb !important;
}

.customer-portal-view-right-number-mobile {
  float: left;
  font-size: 16px;
  /* border: 2px solid #0989cb; */
  border: 2px solid #000;
  border-radius: 50%;
  padding: 4px;
  font-weight: bold;
  /* color: #0989cb; */
  width: 35px;
  padding-left: 11px;
}

.customer-portal-view-right-heading-title-mobile .customer-portal-view-right-heading-text-mobile {
  margin: auto;
  vertical-align: middle;
  font-size: 12px;
  margin-top: 9px;
  margin-left: 40px;
  font-weight: bold;
  cursor: pointer;
}

.customer-portal-view-right-heading-title-mobile {
  /* float: left; */
}

.customer-portal-view-right-heading-text {
  margin: auto;
  vertical-align: middle;
  font-size: 18px;
  margin-top: 12px;
  margin-left: 100px;
  font-weight: bold;
  cursor: pointer;
}

.customer-portal-view-right-heading-title-mobile.active .customer-portal-view-right-heading-text-mobile {
  color: #0989cb;
}

.customer-portal-view-right-heading-title.active .customer-portal-view-right-heading-text {
  color: #0989cb;
}

.customer-portal-view-right-panel {
  padding: 40px;
}

.customer-portal-view-right-sub-heading {
  font-size: 12px;
}

.customer-portal-view-right-heading {
  font-size: 26px;
  font-weight: bold;
}

.customer-portal-view-right-body {
  margin-top: 25px;
}

.customer-portal-view-right-body-mobile {
  margin-top: 25px;
  padding-left: 20px;
}

.customer-portal-view-right-products {
  margin-top: 50px;
}

.customer-portal-view-right-product {
  height: 170px;
  border: 1px solid #ccc;
  float: left;
  width: 150px;
  margin-right: 70px;
  margin-bottom: 50px;
  border-radius: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.customer-portal-view-right-product-mobile {
  height: 170px;
  border: 1px solid #ccc;
  float: left;
  width: 150px;
  margin-right: 30px;
  margin-bottom: 30px;
  border-radius: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.web-customer-portal-order-complete {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.mobile-customer-portal-order-complete {
  margin: 20px;
  /* width: 50%; */
  padding: 10px;
}

.customer-portal-view-order-complete-sub-heading {
  color: #a9adb6;
}

.customer-portal-order-heading {
  margin-top: 50px;
  margin-bottom: 30px;
}

.customer-portal-view-order-message {
  margin-top: 30px;
  color: #a9adb6;
}

.customer-portal-view-right-product-image img {
  width: 150px;
  height: 120px;
  border-radius: 15px 15px 0px 0px;
}

.customer-portal-view-right-product-label {
  text-align: center;
  margin: auto;
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

.customer-engagement-portal-date-selection {
  padding: 20px;
}

.font-weight-600 {
  font-weight: 600;
}

.customer-engagement-portal-datepicker {
  margin-top: 20px;
  width: 400px;
}

.customer-engagement-portal-datepicker-mobile {
  margin-top: 20px;
}

.rmdp-calendar {
  height: 350px !important;
  width: 400px !important;
}

.customer-engagement-portal-datepicker-mobile .rmdp-calendar {
  height: auto !important;
  min-width: 320px !important;
  width: max-content !important;
}

.rmdp-day-picker {
  display: block !important;
}

.rmdp-week {
  margin-top: 10px !important;
}

.customer-engagement-portal-each-date {
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  background-color: white;
  box-shadow: 0px 2px 5px 2px #ccc;
}

.job-details-customer-portal-details {
  margin-bottom: 20px;
}

.job-details-customer-heading {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}

.job-details-customer-portal-details-heading {
  font-weight: 500;
}

.job-details-customer-portal-details-date {
  font-weight: 500;
  margin-top: 10px;
}

.job-details-customer-portal-each-details {
  margin-bottom: 5px;
  line-height: 24px;
}

.customer-engagement-portal-dates-div {
  padding: 20px;
  width: 450px;
  text-align: center;
  height: 405px;
  overflow: scroll;
}

.customer-engagement-portal-dates-div-mobile {
  padding: 20px;
  width: max-content;
  text-align: center;
  height: 405px;
  overflow: scroll;
  min-width: 320px !important;
}

.customer-portal-view-dates-list {
  padding: 20px;
  min-width: max-content;
  text-align: center;
  height: 405px;
  overflow: scroll;
  margin-top: 20px;
}

.customer-portal-view-dates-list-mobile {
  /* padding: 20px; */
  padding-right: 20px;
  min-width: max-content;
  text-align: center;
  height: auto;
  overflow: scroll;
  margin-top: 20px;
  max-height: 350px;
}

.customer-portal-view-right-heading-title-mobile-single {
  margin-left: 20px !important;
}

.customer-portal-view-right-heading-title-mobile-single .customer-portal-view-right-heading-text-mobile {
  margin-left: 60px !important;
  font-size: 14px !important;
  margin-top: 6px !important;
}

.customer-portal-view-each-date {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  background-color: white;
  box-shadow: 0px 2px 5px 2px #ccc;
  cursor: pointer;
}

.customer-portal-view-each-date.active {
  border: 3px solid #0989cb;
}

.customer-engagement-portal-date-button {
  margin-right: 50px;
  float: right;
}

.customer-portal-view-date-selection-button {
  margin-right: 50px;
  float: right;
  margin-top: 20px;
}

.customer-portal-view-date-selection-button-mobile {
  margin-top: 20px;
  width: 100%;
  padding-right: 15px;
}

.height-100-vh {
  height: 100vh;
}

.customer-engagement-portal-date {
  background-color: #059669;
  color: white;
  padding: 25px;
  border-radius: 10px;
}

.customer-engagement-portal-date:disabled {
  background-color: #059669;
  color: white;
  padding: 25px;
  border-radius: 10px;
  opacity: 0.5;
}

.customer-engagement-portal-date-mobile {
  background-color: #059669;
  color: white;
  padding: 25px;
  border-radius: 10px;
  width: 100%;
}

.customer-portal-delete-date {
  font-weight: bold;
  color: red;
  font-size: 18px;
  margin-top: -7px;
  cursor: pointer;
  padding: 5px;
}

.customer-engagement-portal-date-mobile:disabled {
  background-color: #059669;
  color: white;
  padding: 25px;
  border-radius: 10px;
  opacity: 0.5;
  width: 100%;
}

.mr-100 {
  margin-right: 100px;
}

.customer-engagement-portal-date:hover {
  background-color: #059669;
  color: white;
  padding: 25px;
  border-radius: 10px;
}

.mr-50 {
  margin-right: 50px;
}

.company-settings-main-div {
  margin-top: 10px;
}

.trial-account-type {
  width: max-content;
  margin-bottom: 10px;
}

.company-setting-input {
  margin-bottom: 16px !important;
}

.company-settings-heading {
  font-size: 18px;
  font-weight: bold;
  color: rgb(107, 114, 128);
}

.company-settings-left-panel {
  width: 40%;
  text-align: right;
}

.company-settings-right-panel {
  width: 60%;
  margin-left: 18px;
}

.company-settings-radio {
  margin-left: 7px;
}

.company-settings-button-div {
  text-align: center;
  margin-top: 40px;
}

.worktype-settings-content {
  display: flex;
}

.additional-email-label-text {
  color: #b9bcc4;
  margin-left: 5px;
}

.additional-email-label-text-note {
  color: #b9bcc4;
  margin-left: 5px;
  font-style: italic;
  margin-top: 10px;
  font-size: 12px;
}

.MuiTablePagination-select {
  padding-top: 15px;
}

.company-settings-label {
  padding: 5px;
  font-size: 16px;
  /* line-height: 35px; */
  margin: 8px;
  height: 40px;
}

.no-questions-order {
  font-weight: bold;
  margin: 30px;
  text-align: center;
}

.error-field {
  color: red !important;
  margin-left: 7px;
}

.view-work-type-button {
  padding: 10px 30px;
}

.form-terms-checkbox {
  position: absolute;
  float: left;
  left: -10px;
}

.form-terms-checkbox-label {
  top: 10px;
  position: relative;
  margin-left: 20px;
}

.dialog-button-invite:disabled {
  cursor: auto;
  opacity: 0.6;
  color: white !important;
}

.contact-details-new-job {
  margin-left: 0px;
  margin-top: 30px;
  position: absolute;
}

.contact-details-new-job button,
.contact-details-new-job button:hover {
  background-color: #059669;
  width: 100% !important;
}

.invoice-paid-status {
  /* height: 50%; */
  max-width: 50%;
  text-align: center;
  margin: auto;
  margin-top: 20px;
}

.invoice-image-status {
  width: max-content;
}

.invoice-image-status img {
  max-height: 600px;
}

.invoice-pdf-status {}

.invoice-paid-status-button {
  text-align: center;
  margin-top: 50px;
}

.web-view-work-type-dialog-content {
  padding-top: 0px !important;
}

.web-view-work-type-dialog-content.MuiDialogContent-root>.MuiFormControl-root {
  top: 80px;
  position: absolute;
}

.work-types-view-content {
  overflow: scroll;
  position: relative;
  max-height: 345px;
}

.min-height-200 {
  min-height: 200px;
}

.mtp-18 {
  margin-top: 18px;
}

.web-job-quote-line-items-header {
  margin-bottom: 10px;
}

.job-quote-line-item {
  background-color: white;
  padding: 12px;
  border: 1px solid #CCC;
  border-radius: 10px;
  margin-bottom: 15px;
}

.quote-line-item-manage {
  cursor: pointer;
}

.job-quote-line-item-name {
  text-decoration: underline;
}

.job-quote-line-items-new,
.job-quote-line-items-new:hover {
  background-color: #CCC;
  color: black;
  width: 100px;
}

.add-quote-line-item-left-button {
  float: left;
}

.web-add-quote-line-item-dialog-content {
  display: block !important;
  padding: 20px 20px !important;
}

.add-quote-line-item-dialog-div,
.add-chargeback-dialog-div {
  margin-top: 20px;
}

.add-chargeback-dialog-div {
  padding: 0px 35px;
  padding-bottom: 10px;
}

.add-quote-line-item-tabs .MuiTabs-flexContainer {
  display: block;
  text-align: center;
}

.add-quote-line-item-left-button,
.add-chargeback-left-button {
  background-color: #e0f2fe !important;
  color: #0284C7;
  width: 90px;
  border-radius: 5px;
}

.view-work-types-tab-content {
  margin-top: 10px;
}

.width-50 {
  width: 50%;
}

.wp-40 {
  width: 40%;
}

.width-85 {
  width: 85%;
}

.width-83 {
  width: 83%;
}

.wp-20 {
  width: 20%;
}

.wp-50 {
  width: 50%;
}

.wp-60 {
  width: 60%;
}

.bid-worksheet-about-details {
  margin-top: 20px;
  line-height: 30px;
  padding: 20px 20px 20px 0px;
}

.bid-worksheet-edit-valid,
.bid-worksheet-edit-valid:hover {
  align-items: center;
  text-align: center;
  width: 100%;
  background-color: transparent;
  color: #111827;
}

.quote-line-item-each-accordion {
  border-top: 1px solid #CCC !important;
}

.quote-bid-worksheet-left-panel {
  padding: 20px;
  height: auto;
  flex: 1 1 28% !important;
}

.quote-bid-worksheet-bottom-content {
  padding: 20px;
}

.bid-worksheet-accordion-heading-content {
  padding: 15px 15px;
}

.mr-10 {
  margin-right: 10px;
}

.bid-worksheet-cost-label {
  margin-left: 10px;
}

.bid-worksheet-quote-line-item-label {
  line-height: 40px;
}

.bid-worksheet-quote-line-item-value {
  line-height: 40px;
  font-weight: bold;
}

.bid-worksheet-about-details-value {
  font-weight: bold;
}

.bid-worksheet-bid-label {
  color: white
}

.bid-worksheet-bid-text {
  color: white;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  display: block;
}

.bid-worksheet-bid-text .MuiOutlinedInput-root {
  width: 100%;
}

.bid-worksheet-tooltip {
  padding: 0px 10px;
}

.add-quote-line-item-attachments-div .each-uploaded-file {
  float: left;
  padding: 5px;
  width: 100%;
}

.quote-line-attachments-heading {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
}

.anchor-link {
  color: #1a0dab;
}

.margin-left-20 {
  margin-left: 20px;
}

.questions-order-action-icon {
  margin: 7px;
  margin-top: 7px !important;
  cursor: pointer;
}

.job-questions-order-action-icon {
  margin: 5px;
  margin-top: 7px !important;
  cursor: pointer;
  width: 24px;
}

.job-detail-questions-content {
  padding: 40px;
  max-width: 60%;
  float: left;
  width: 60%;
  padding-top: 10px;
}

.job-detail-question-order {
  float: left;
  /* margin-left: 100px; */
  padding: 40px;
  width: 40%;
  max-width: 40%;
  padding-top: 10px;
}

.create-new-question-button,
.create-new-question-button:hover {
  background-color: #0284C7;
  float: right;
}

.job-detail-question-heading {
  padding: 10px 0px;
  font-weight: 600;
}

.job-detail-each-question {
  margin-bottom: 15px;
}

.label-file-upload {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-radius: 1rem;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: #f8fafc;
  padding: 30px;
}

.job-detail-question-helptext-content {
  width: 96%;
  color: #6B7280;
  font-size: 14px;
  font-weight: 400;
}

.job-detail-question-helptext {
  margin-bottom: 10px;
}

.job-detail-question-upload-image {
  height: 42px;
  margin: auto;
}

.job-detail-question-upload-heading {
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

.job-detail-question-upload-sub-text {
  opacity: 0.5;
  margin: auto;
  text-align: center;
}

.quote-detail-questions {
  width: 65%;
}

.quote-detail-question-footer {
  margin-top: 45px;
}

.quote-detail-question-footer .create-new-question-button {
  float: none !important;
}

.job-detail-question-upload-heading .upload-button {
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;
}

.quote-detail-question-footer {
  text-align: center;
}

.replace-attachment-btn {
  margin-right: 20px;
}

.replace-attachment-btn:hover {
  background-color: #0284C7;
}

.upload-feed-attachment-content {
  padding: 10px 5px;
}

.upload-feed-attachment-link,
.upload-feed-attachment-remove {
  cursor: pointer;
  text-decoration: underline;
}

.upload-feed-attachment-remove {
  margin-top: 10px;
  margin-bottom: 5px;
}

.conversation-attachment-link {
  background: none !important;
  text-decoration: underline !important;
}

.each-quote-shared-information-question-left {
  /* margin-left: 25px;
  margin-right: 25px; */
  width: 100px;
}

.each-quote-shared-information-question-right {
  width: calc(100% - 100px);
}

.each-quote-shared-information-question {
  margin-bottom: 25px;
}

.each-quote-shared-information {
  border-top: 1px solid #CCC;
  padding-top: 15px;
}

.job-collab-each-item {
  background-color: white;
  padding: 20px;
  max-width: 220px;
  text-align: center;
  float: left;
  margin-left: 40px;
  width: 220px;
}

.job-collab-profile-image-div {
  /* padding-left: 12px; */
}

img.job-collab-profile-image {
  border-radius: 50%;
  height: 120px;
  width: 120px;
  margin: auto;
}

.job-collab-add-button,
.job-collab-add-button:hover {
  background-color: #0284c7;
  color: #f9fafb;
  border-radius: 6px;
}

.job-collab-remove-button,
.job-collab-remove-button:hover {
  background-color: #B7B7B7;
  color: #6B6B6B;
  border-radius: 6px;
}

.job-collab-profile-name {
  margin: 7px 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}

.job-collab-empty-row {
  padding: 10px;
}

.job-detail-collaborators-content {
  padding: 40px;
  max-width: 90%;
  float: left;
  width: 90%;
  padding-top: 10px;
}

.job-collab-profile-role {
  margin: 7px 0px;
  font-weight: bold;
  text-overflow: ellipsis;
  clear: both;
  overflow: hidden;
  white-space: nowrap;
}

.job-collab-profile-image-mock-div {
  background-color: #0284c7;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  margin: auto;
  font-size: 48px;
  color: #FFFFFF;
  align-items: center;
  display: grid;
}

.web-chargebacks-list {
  background-image: none;
  border-radius: 16px;
  overflow: hidden;
  background-color: #075985;
  color: #f3f4f6;
  padding: 16px;
  margin-top: -30px;
}

.each-job-chargeback {
  background-color: #e1e1e1;
  color: black;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  text-decoration: underline;
  cursor: pointer;
  margin: 10px 0px;
}

.invoice-chargeback-amount-content {
  padding: 5px 12px;
}

.view-chargeback-label {
  color: rgb(107, 114, 128);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 3px;
}

.view-chargeback-detail {
  margin: 15px 0px;
}

.view-chargeback-detail {
  margin: 15px 0px;
}

.job-chargebacks-list-content {
  width: 100%;
}

.invoice-chargeback-heading {
  font-weight: bold;
  font-size: 18px;
}

.add-chargeback-label {
  color: #374151;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 8px;
}

.dashboard-partners-list-filter {
  width: 200px;
}

.dasboard-list-filters {
  width: 100%;
}

.dashboard-payment-status-filter {
  width: 150px;
}

.dasboard-list-filters .MuiOutlinedInput-input.MuiInputBase-input {
  padding-bottom: 10px;
}

.dashboard-tabs .MuiTabs-scroller .MuiTabs-flexContainer {
  padding-left: 20px;
}

.accounts-list-table-button-div {
  float: right;
  width: 100%;
}

.accounts-list-table-button {
  float: right;
  margin-top: -60px;
  margin-right: 10px;
}

.picklist-values-add {
  margin-left: -30px;
  margin-top: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.picklist-values-text {
  width: 80%;
}

.picklist-values-delete {
  margin-top: 5px;
  cursor: pointer;
}

.picklist-value-content {
  margin: 15px 0px;
}

.picklist-value-content:first-of-type {
  margin-top: 0px;
}

.create-custom-field-save-create {
  margin: 0px 20px;
}

.attachment-slider-images {
  width: 100%;
}

.margin-left-10 {
  margin-left: 10px;
}

.roofing-profile-setup-main {
  padding: 20px;
}

.roofing-profile-setup-input-label {
  text-align: right;
  padding: 20px;
}

.roofing-profile-setup-input-labels {
  width: max-content;
  float: left;
}

.roofing-profile-setup-input-fields {
  float: left;
  width: 400px;
}

.roofing-profile-setup-company-name {
  margin-top: -10px;
}

.roofing-profile-setup-DBA {
  margin-top: -3px;
}

.roofing-profile-setup-contractor-license {
  margin-top: -5px;
}

.roofing-profile-setup-company-description {
  margin-top: 35px;
}

.roofing-profile-setup-partner-information {
  margin-top: 35px;
}

.roofing-profile-setup-input-main {
  /* margin-top: 50px; */
}

.roofing-profile-setup-footer {
  width: 100%;
  text-align: center;
}

.dialog-button-roofing-profile-setup,
.dialog-button-roofing-profile-setup:hover {
  background-color: #059669 !important;
  color: #f9fafb !important;
  border-radius: 6px;
  /* float: right; */
  /* padding-left: 20px; */
  text-align: center;
  width: 100px;
  padding: 25px;
  font-size: 18px;
}

.roofing-profile-setup-upload-document {
  margin-right: 40px;
}

.roofing-profile-view-heading {
  font-size: 32px;
  font-weight: bold;
}

.roofing-profile-view-labels {
  font-size: 16px;
  margin: 15px 0px;
  font-weight: 500;
}

.roofing-profile-view-left {
  width: 65%;
}

.roofing-profile-view-right {
  width: 30%;
  float: left;
  margin-left: 50px;
  margin-top: 20px;
}

.roofing-profile-view-account-logo {
  width: 320px;
}

.company-logo-more-tabs {
  margin-top: -10px;
  height: 80px;
}

.find-partner-profile-view-link {
  cursor: pointer;
  text-decoration: underline;
}

.roofing-partner-profile-back {
  float: left;
  width: auto;
  cursor: pointer;
}

.roofing-partner-profile-arrow {
  float: left;
}

.roofing-partner-profile-header {
  margin: 20px 0px;
  display: flex;
}

.roofing-partner-profile-header-partner {
  margin: 20px 0px;
  display: flex;
  width: 60px;
}

.roofing-partner-profile-view-partner {
  margin-top: 40px;
}

.roofing-partner-profile-add-btn-div {
  float: left;
  width: 100%;
  text-align: center;
  margin-top: -10px;
}

.roofing-partner-profile-view-back-btn {
  float: right;
  margin-top: -19px;
  margin-left: 10px;
}

.partner-account-heading {
  text-decoration: underline;
  cursor: pointer;
}

.comapny-settings-request-document {
  margin-left: 15px;
}

.company-settings-request-document-text {
  width: 150px;
  margin-top: 5px;
}

.horizantal-stright-line {
  border-top: 1px solid #CCC;
  margin-bottom: 10px;
}

/* Step Indicator */
.MuiStep-indicator .MuiStepIndicator-root.MuiStepIndicator-colorPrimary.MuiStepIndicator-variantSolid {
  background-color: #0b6bcb !important;
}

.MuiStep-indicator .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  font-size: 12px !important;
  fill: white !important;
}

.MuiStep-indicator .MuiStepIndicator-root.MuiStepIndicator-colorPrimary.MuiStepIndicator-variantOutlined {
  border-color: #0b6bcb;
  --Icon-color: #0b6bcb;
  color: #0b6bcb;
  border: 1px solid #0b6bcb;
}

.MuiStep-indicator .MuiStepIndicator-root.MuiStepIndicator-colorPrimary.MuiStepIndicator-variantOutlined svg {
  fill: #0b6bcb !important;
}

.MuiStep-root.MuiStep-vertical:after {
  top: unset !important;
}

.MuiStep-root.MuiStep-vertical:last-of-type:after {
  top: unset !important;
  display: none !important;
}

.MuiStep-root.MuiStep-vertical:nth-of-type(5) {
  min-width: 154px !important;
}

.MuiStepper-root .MuiTypography-root.MuiTypography-h4 {
  font-size: 12px;
}

ol.MuiStepper-root.MuiStepper-horizontal.MuiStepper-sizeMd {
  width: 80%;
  text-align: center;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0px;
  margin-right: 100px;
}

.MuiStepper-root.MuiStepper-horizontal .MuiStep-root.MuiStep-vertical.Mui-disabled .MuiStepIndicator-root.MuiStepIndicator-colorNeutral.MuiStepIndicator-variantOutlined {
  border: 1px solid #9fa6ad;
}

.MuiStepper-root.MuiStepper-horizontal .MuiStep-root.MuiStep-vertical.Mui-disabled {
  color: #9fa6ad;
}

.job-detail-stepper {
  width: 95%;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.job-detail-stepper-logo {
  margin-top: 21px !important;
}

/* @media only screen and (min-width: 1200px) {
  .job-detail-stepper{
    display: block;
  }
} */
@media only screen and (max-width: 1500px) {
  .job-detail-stepper{
    display: none;
  }
}
/* Step Indicator End */